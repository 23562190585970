<template>
  <div class="dashboard progress-history-page">
    <div class="progress-page-top">
      <tabs/>
      <div class="year-filter-wrapper">
        <div class="container">
          <div class="select-wrapper">
            <select v-model="selectedYear" @change="getHistory()">
              <option v-for="year in yearList" :value="year">{{ year }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="progress-history-list-wrapper">
        <div class="container">
          <div class="progress-history-list">
            <div class="progress-history-list-month" v-for="(month,monthIndex) in historyItems" :key="monthIndex">
              <div class="progress-history-list-month-heading d-flex justify-content-between align-items-center">
                <p class="progress-history-list-month-title section-title">{{ monthIndex }}</p>
                <p class="progress-history-list-month-subtitle">{{ countable[monthIndex] }} {{ countable[monthIndex] > 1 ? 'Treinos' : 'Treino' }}</p>
              </div>
              <div class="progress-history-list-month-body">
                <div class="progress-history-item" v-for="(date,dateIndex) in month" :key="dateIndex">
                  <div class="progress-history-item-btn d-flex justify-content-between align-items-center" @click="toggleHistoryItem">
                    <p class="progress-history-item-btn-title">{{ dateIndex }}</p>
                    <div class="d-flex justify-content-end align-items-center">
                      <p class="progress-history-item-btn-text">{{ date.length }} {{ date.length > 1 ? 'Treinos' : 'Treino' }}</p>
                      <div class="progress-history-item-btn-icon">
                        <chevronDown/>
                      </div>
                    </div>
                  </div>
                  <div class="progress-history-item-body">
                    <div class="progress-history-item-body-flex d-lg-flex justify-content-lg-start align-items-lg-start">
                      <div class="progress-history-item-body-group">
                        <p class="progress-history-item-body-group-title">Treino</p>
                        <p class="progress-history-item-body-group-text" v-for="(workout,workoutIndex) in date" :key="workoutIndex">{{ workout.title }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tabs from "@/components/progress/progress-tabs.vue";
import chevronDown from "@/components/svg/chevron-down.vue";
import axios from "axios";

export default {
  name: "progress-history",
  data() {
    return {
      lang: this.$root.siteLang,
      historyItems: [],
      errors: [],
      countable: {},
      yearList: [],
      selectedYear: new Date().getFullYear()
    }
  },
  metaInfo () {
    return {
      title: this.$root.translations.progresso,
    }
  },
  components: {
    tabs,
    chevronDown
  },
  methods: {
    toggleHistoryItem(e) {
      let clickedItem = e.currentTarget.closest('.progress-history-item');
      let items = Array.from(document.querySelectorAll('.progress-history-item'));

      if (clickedItem.classList.contains('active')) {
        clickedItem.classList.remove('active');
      } else {
        items.forEach(node => {
          node.classList.remove('active');
        });

        clickedItem.classList.add('active');
      }
    },
    getHistory() {
      this.$root.pageTitle = this.$root.translations.progresso;
      let authKey = window.$cookies.get("authKey");
      axios
          .get(process.env.VUE_APP_API_URL + 'history?lang=' + this.lang + '&year=' + this.selectedYear, {
            headers: {
              Authorization: 'Bearer ' + authKey
            }
          })
          .then((response) => {
            this.historyItems = response.data;

            for (var month in this.historyItems) {
              this.countable[month] = 0
              for (var val in this.historyItems[month]) {
                let value = this.countable[month]
                this.countable[month] = value + this.historyItems[month][val].length
              }
            }
          })
          .catch((e) => {
            this.errors.push(e);
          }).finally((e) => {
      });
    }
  },
  created() {
    this.getHistory()
    let currentYear = new Date().getFullYear()
    for (let i = currentYear; i + 5 > currentYear; i--) {
      if (i >= 2021) {
        this.yearList.push(i)
      }
    }
  }
}
</script>