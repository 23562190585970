<template>
  <div class="box-page-menu">
    <div class="container">
      <div class="box-tabs-wrapper d-xl-flex justify-content-between align-items-end">
        <div class="box-tabs d-flex justify-content-start">
          <router-link to="/progress-achievements" class="box-tab-item">Conquistas</router-link>
          <router-link to="/progress-history" class="box-tab-item">Histórico</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import plus from "@/components/svg/plus.vue";

export default {
  name: "tabs",
  components: {
    plus
  },
}
</script>